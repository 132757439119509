<template>
  <div class="news con_area_bgcolor">
    <div class="index_news con_area">
      <div class="w11402">
        <h2>
          <span class="title">News & Events</span>
        </h2>
        <el-skeleton
          style="width: 100%; margin-top: 60px"
          :loading="newsLoading"
          animated
          :count="3"
        >
          <template slot="template">
            <div
              v-if="cWidth > 1024"
              style="
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;
              "
            >
              <el-skeleton-item
                variant="image"
                style="width: 28vw; height: 32rem"
              />
              <div style="width: calc(100% - 28vw - 15px)">
                <el-skeleton-item variant="h1" style="margin-top: 10px" />
                <!-- <div style="margin-top: 16px">
                  <el-skeleton-item variant="h6" style="width: 30%" />
                </div> -->
                <div style="margin-top: 20px">
                  <el-skeleton-item
                    v-for="(item, index) in 10"
                    :key="index"
                    variant="text"
                    style="margin-right: 16px; margin-bottom: 5px"
                  />
                </div>
              </div>
            </div>
            <div v-else style="margin-bottom: 30px">
              <el-skeleton-item
                variant="image"
                style="width: 100%; height: 60vw"
              />
              <div style="width: 100%">
                <el-skeleton-item variant="h1" style="margin: 20px 0 0 0" />
                <!-- <div style="margin-top: 16px">
                  <el-skeleton-item variant="h6" style="width: 30%" />
                </div> -->
                <div style="margin-top: 15px">
                  <el-skeleton-item
                    v-for="(item, index) in 8"
                    :key="index"
                    variant="text"
                    style="margin-right: 16px; margin-bottom: 5px"
                  />
                </div>
              </div>
            </div>
          </template>

          <template>
            <div
              class="justify_align news_contaner"
              v-for="(item, index) in newsList"
              :key="index"
              @click="tz('News_desc', item.id)"
            >
              <div
                class="index_news_img"
                :style="{
                  background: 'url(' + Api + item.imgUrl + ') no-repeat center',
                }"
              ></div>
              <!-- <img :src="Api + item.imgUrl" alt="" /> -->
              <div class="index_news_info">
                <p>{{ item.title }}</p>
                <!-- <a>{{ item.time || "-" }}</a> -->
                <span>{{ item.introduction }}</span>
              </div>
            </div>
          </template>
        </el-skeleton>
        <div class="pageCon" v-if="total > 10">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage3"
            :page-size="100"
            layout="prev, pager, next, jumper"
            :total="1000"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sysApi from "@/api/sys/articles/sysApi";

export default {
  data() {
    return {
      cWidth: sessionStorage.getItem("clientWidth"),
      newsLoading: false,
      loadText: "加载中",
      currentPage: 1, //当前页数
      pageSize: 10, //每页数量
      total: 0, //总条数
      newsList: [],
    };
  },
  created() {
    this.getList(395358633667429);
  },
  methods: {
    // 获取数据
    /**
     * typeid
     * 新闻：395358633667429
     * 轮播图：391234533667429
     */
    async getList(typeid) {
      this.newsLoading = true;
      const item = {
        Typeid: typeid,
        Title: "",
        Current: this.currentPage,
        Size: this.pageSize,
        SortField: "",
        SortOrder: "",
        SearchKey: "",
      };
      try {
        const resultData = await sysApi.GetArticlesByTypeID(item); //这里token存储，放到请求拦截裂存储
        this.newsList = resultData.records;
        this.total = resultData.total;
        this.newsLoading = false;
      } catch (err) {
        this.$message({
          type: "error",
          message: err,
          offset: 100,
        });
      }
    },
    tz(name, paramsvalue) {
      $(window).scrollTop(0);
      if (paramsvalue) {
        this.$router.push({
          name: name,
          query: { id: paramsvalue },
        });
      } else {
        this.$router.push({
          name: name,
        });
      }
    },
    handleSizeChange(valu) {
      this.pageSize = valu;
      this.getNewsList();
    },
    handleCurrentChange(valu) {
      this.currentPage = valu;
      this.getNewsList();
    },
  },
};
</script>

<style>
.news {
  width: 100%;
}
.news .index_news h2 > span {
  background: #fafafa;
}
.index_news > div > .pageCon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
</style>